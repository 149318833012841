.yc-first-top-game {
  background-color: rgba(255, 255, 255, 0.1);
  width: 800px;
  height: 100%;
  border-radius: 20px;
  white-space: normal;
  display: flex;
  color: white !important;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
    //outline: 1px solid #ffffffb8;
    //outline-offset: -1px;
    transition: all 0.2s ease-in-out;
  }

  .col-left {
    flex: 1;
    padding: 10px;
  }

  .col-right {
    flex: 1;
    padding: 20px;

    .title-game {
      font-size: 24px;
      margin-bottom: 0px;
      color: white;
      font-weight: bold;
      margin-bottom: 16px;
    }

    p {
      min-height: 78px;
    }

    .content-bt-play {
      width: 100%;
      text-align: right;

      .bt-play {
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 20px;
        border: 0;
        opacity: 1;
        &:hover {
          background-color: rgba(255, 255, 255, 0.3);
          transition: all 0.2s ease-in-out;
        }
      }
    }
  }
}
