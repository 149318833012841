#root-game {
  .header-similar-category-style {
    display: flex;
    color: white;

    .ico-style {
      width: 30px;
      font-size: 20px;
    }
  }

  h2 {
    font-size: 24px;
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
  }

  .games {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    .game {
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
  .player-game-page {
    display: flex;
    height: 600px;
    border-radius: 20px;
    white-space: normal;
    color: white !important;
    margin-bottom: 30px;

    .col-left {
      .player-style {
        border-radius: 20px;
        background-color: #1e01a0;

        iframe {
          overflow: hidden;
        }
      }
    }
    .col-right-infogame {
      background-color: rgba(255, 255, 255, 0.1);
      //width: 572px;
      width: 30%;
      border-radius: 20px;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .description-header {
        .icon-style {
          width: 148px;
          border-radius: 32px;
          margin-bottom: 32px;
          background-color: #1e01a0;
        }

        .content-bt-category {
          width: 100%;

          .bt-category {
            height: 60px;
            line-height: 40px;
            color: #ffffff;
            padding-top: 0px;
            padding-left: 20px;
            padding-right: 20px;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            border: 0;
            opacity: 1;
            &:hover {
              background-color: rgba(0, 0, 0, 0.3);
              transition: all 0.2s ease-in-out;
            }

            .ico-style {
              padding-right: 10px;
            }
          }
          .separation {
            margin-right: 16px;
          }
        }
      }
      .description-content {
        height: 100%;
        padding-top: 20px;
        .title-game {
          font-size: 36px;
          color: white;
          font-weight: bold;
          margin-bottom: 8px;
        }
      }

      .content-bt-store {
        width: 100%;

        .bt-store {
          height: 40px;
          line-height: 40px;
          color: #ffffff;
          padding-top: 0px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 20px;
          border: 0;
          opacity: 1;
          &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            transition: all 0.2s ease-in-out;
          }

          .ico-style {
            padding-right: 10px;
          }
        }
        .separation {
          margin-right: 16px;
        }
      }
    }

    .col-right-studio {
      background-color: rgba(0, 0, 0, 0.1);
      //width: 572px;
      width: 20%;
      border-radius: 20px;
      margin-left: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;

      .studio-header {
        text-align: center;
        margin-top: 20%;
        .visual-style {
          width: 148px;
          margin-bottom: 32px;
        }
      }
      .studio-content {
        padding-bottom: 20px;
        .title-studio {
          text-align: center;
          font-size: 24px;
          color: white;
          font-weight: bold;
          margin-bottom: 8px;
        }
      }

      .content-bt-studio {
        width: 100%;
        text-align: center;

        .bt-studio {
          height: 40px;
          line-height: 40px;
          color: #ffffff;
          padding-top: 0px;
          padding-left: 20px;
          padding-right: 20px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 20px;
          border: 0;
          opacity: 1;
          &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }
}
