#header-component {
  left: 0;
  position: fixed;
  z-index: 100;
  width: 100vw;
  min-width: 1240px;
  box-shadow: 0px 0px 3px;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  opacity: 1;
  backdrop-filter: blur(60px);
  padding: 10px 20px;
  justify-content: space-between;

  > .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .links {
    .navbar-content {
      a {
        display: inline-block;
        padding: 10px 10px 0 5px;
        text-decoration: none;
        color: white;
        font-weight: 500;
        text-align: center;
        small {
          display: block;
          font-weight: normal;
          font-size: 10px;
          line-height: 10px;
        }
      }
      .login {
        margin-left: 0px;
        margin-right: 10px;
        // margin-top: 6px;
        height: 40px;
        line-height: 40px;
        color: #ffffff;
        padding-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
        background: transparent linear-gradient(56deg, #007bff 0%, #af50ff 100%) 0% 0% no-repeat padding-box;
        border-radius: 20px;
        border: 0;
        opacity: 1;
      }
      .admin {
        color: red;
      }
    }
  }
  .dropdown-menu {
    width: 200px;
    a {
      padding-top: 6px !important;
      padding-bottom: 6px !important;
    }
    hr {
      margin: 8px 10px;
    }
  }
  .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
  }

  .nav-categories {
    display: flex;
    color: white;

    .nav-item {
      display: flex;
      padding-right: 50px;
    }

    .ico-nav-style {
      width: 30px;
      font-size: 20px;
      margin-top: 7px;
    }
  }
}
