.upload-file {
  display: inline-block;
  border: 1px dotted #cccccc;
  background-color: #f0f0f0;
  margin-bottom: 24px;
  overflow: hidden;
  .ant-spin-nested-loading, .ant-spin-container, .ant-row.ant-form-item, .ant-col.ant-form-item-control, .ant-form-item-control-input {
    height: 100%;
  }
  .ant-row.ant-form-item {
    margin: 0;
    .ant-upload.ant-upload-select {
      display: block;
      overflow: hidden;
      .ant-upload {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}