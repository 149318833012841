body {
  background: transparent linear-gradient(323deg, #000050 0%, #2e00c9 66%, #a800ff 100%) 0% 0% no-repeat padding-box;
  background-attachment: fixed;
  opacity: 1;

  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #070162;
  }

  &::-webkit-scrollbar-thumb {
    background: #574bff;
    border-radius: 20px;
    border: 2px solid #070162;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}

#app {
  .ant-form-vertical .ant-form-item-label {
    padding-bottom: 4px;
  }

  .float-left {
    float: left;
  }

  > .content {
    padding: 92px 20px 20px 20px;
    // background-color: #ffffff;
    min-height: 100vh;
    box-shadow: 0px 0px 1px;
  }
}

.ant-card {
  background-color: #fafafa;
  .ant-card-head {
    background-color: #1890ff;
    color: white;
  }
}
