.yc-game-preview {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  transition: all 0.1s ease-in-out;
  border: 1px solid #ffffffb8;
  box-shadow: 0px 0px 20px #37445f26;
  &:hover {
    transform: scale(1.05);
    video {
      display: block;
    }
    box-shadow: none;
  }
  img,
  video {
    position: absolute;
    top: 0;
    width: 100%;
  }
  video {
    display: none;
  }
}
