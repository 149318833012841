#root-home {
  .categories {
    .category {
      margin-bottom: 20px;

      .header-category-style {
        display: flex;
        color: white;

        .ico-style {
          width: 30px;
          font-size: 20px;
        }
      }

      h2 {
        font-size: 24px;
        margin-bottom: 0px;
        color: white;
        font-weight: bold;
      }

      .games {
        overflow-x: scroll;
        display: flex;
        white-space: nowrap;
        margin-left: -10px;
        margin-right: -10px;
        &::-webkit-scrollbar {
          display: none;
        }
        .game {
          padding: 10px;
          display: inline-block;
        }
      }
      .scrollright {
        margin-right: -20px;
      }

      .content-bt-collapse {
        width: 100%;
        text-align: right;

        .bt-collapse {
          height: 30px;
          color: #ffffff;
          padding-top: 0px;
          padding-left: 7px;
          padding-right: 7px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 20px;
          border: 0;
          opacity: 1;
          margin-left: 5px;
          &:hover {
            background-color: rgba(255, 255, 255, 0.3);
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }

    .category-style {
      background-color: rgba(255, 255, 255, 0.1);
      width: 100%;
      height: 100%;
      border-radius: 20px;
      padding: 10px;
      padding-bottom: 0;
    }
  }
}
