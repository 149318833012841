.c-title {
  font-size: 32px;
  margin-bottom: 30px;
  border-bottom: 1px solid black;
  .icon {
    background-color: #7134ff;
    border-radius: 50%;
    vertical-align: middle;
    padding: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: 4px;
    span {
      font-size: 0.7em;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
