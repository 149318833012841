.root-sign-up {
  background-color: rgba(255, 255, 255, 0.1);
  width: 400px;
  height: 100%;
  border-radius: 20px;
  white-space: normal;
  margin: 60px auto 0;
  padding: 40px;

  .ant-btn-primary {
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 20px;
    border: 0;
    opacity: 1;
    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
      transition: all 0.2s ease-in-out;
    }
  }

  a {
    color: #b8a5ff;
    &:hover {
      color: white;
      transition: all 0.2s ease-in-out;
    }
  }

  .title-sign-up {
    font-size: 32px;
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
    padding-bottom: 16px;
  }

  .ant-form-item-required {
    color: white !important;
  }

  .block {
    width: 300px;
    margin: auto;
    a {
      margin-left: 10px;
    }
  }
}
